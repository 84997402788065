<script>
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Register': 'Cadastrar',
    },
    es: {
      'Register': 'Registrar',
    }
  },
  props: {
    id: {
      default: ""
    },
    variations: {
      default: ""
    },
  },
  components: { },
  data() {
    return {
      alert: {
        manage: { type: '', message: '' },
        register: { type: '', message: '' },
        edit: { type: '', message: '' },
      },
      address: {
        loading: false,
        errored: false,
        status: false,
        list: []
      },
      modal: {
        register: false,
        manage: false,
      },
      register: {
        loading: false,
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
      },
      edit: {
        loading: false,
        id: '',
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
      },
      shipping: {
        loading: false,
        method: '',
        services: []
      },
      cart: {
        items: {
          total: 0,
          list: null
        },
        subtotal: '0.00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: '0.00'
      }
    };
  },
  validations: {
    register: {
      zipcode: { required },
      address: { required },
      number: { required },
      complement: { },
      district: { required },
      city: { required },
      state: { required },
    },
    edit: {
      zipcode: { required },
      address: { required },
      number: { required },
      complement: { },
      district: { required },
      city: { required },
      state: { required },
    }
  },
  methods: {
    getCart () {
      this.cart = this.$parent.cart
      this.shipping.method = this.$parent.cart.shipping.method
    },
    getAddress() {
      this.address.loading = true

      api
        .get('store/address')
        .then(response => {
          if (response.data.status=='success') {
            this.address.status = true
            this.address.list = response.data.list

            this.getShipping()
          } else {
            this.address.status = false
            this.address.list = []
            this.modal.manage = false
          }

          this.address.loading = false
        })
        .catch(error => {
          this.address.errored = error
          this.address.loading = false
        })
    },
    registerAddress() {
      this.register.loading = true
      this.$v.register.$touch();

      if (!this.$v.register.$invalid) {
        api
          .post('store/address', {
            zipcode: this.register.zipcode,
            address: this.register.address,
            number: this.register.number,
            complement: this.register.complement,
            district: this.register.district,
            city: this.register.city,
            state: this.register.state,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.register.zipcode = ''
              this.register.address = ''
              this.register.number = ''
              this.register.complement = ''
              this.register.district = ''
              this.register.city = ''
              this.register.state = ''
              this.$v.register.$reset()

              this.modal.register = false

              localStorage.removeItem('cart')
              this.$parent.getCart()

              this.getCart()
              this.getAddress()
            } else {
              this.alert.register.type = 'alert-danger'
              this.alert.register.message = response.data.message
            }

            this.register.loading = false
          })
          .catch(error => {
            if (error) {
              this.register.loading = false
            }
          })
      } else {
        this.register.loading = false
      }
    },
    showAddress(id) {
      if (this.address.list[id]) {
        this.modal.manage = false
        this.modal.edit = true

        this.edit = this.address.list[id]
        this.edit.id = id
      }
    },
    useAddress(id) {
      this.modal.manage = false

      api
        .post('store/address/use',{
          id: id
        })
        .then(response => {
          if (response.data.status=='success') {
            this.modal.manage = false

            localStorage.removeItem('cart')
            this.$parent.getCart()

            this.getCart()
            this.getAddress()
          }
        })
    },
    editAddress() {
      this.edit.loading = true
      this.$v.edit.$touch();

      if (!this.$v.edit.$invalid) {
        api
          .post('store/address/edit', {
            id: this.edit.id,
            zipcode: this.edit.zipcode,
            address: this.edit.address,
            number: this.edit.number,
            complement: this.edit.complement,
            district: this.edit.district,
            city: this.edit.city,
            state: this.edit.state,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.edit.zipcode = ''
              this.edit.address = ''
              this.edit.number = ''
              this.edit.complement = ''
              this.edit.district = ''
              this.edit.city = ''
              this.edit.state = ''
              this.$v.edit.$reset()

              this.modal.edit = false

              localStorage.removeItem('cart')
              this.$parent.getCart()

              this.getCart()
              this.getAddress()

              this.modal.manage = true
            } else {
              this.alert.edit.type = 'alert-danger'
              this.alert.edit.message = response.data.message
            }

            this.edit.loading = false
          })
          .catch(error => {
            if (error) {
              this.edit.loading = false
            }
          })
      } else {
        this.edit.loading = false
      }
    },
    delAddress(id) {
      if (id) {
        api
          .post('store/address/delete', {
            id: id,
          })
          .then(response => {
            if (response.data.status=='success') {
              localStorage.removeItem('cart')
              this.$parent.getCart()

              this.getCart()
              this.getAddress()
            }
          })
      }
    },
    searchCepRegister() {
      if (this.register.zipcode.length == 9) {
        api
          .get("https://cep.m2n.com.br/" + this.register.zipcode.replace(/\D/g, ""))
          .then(response => {
            if (response.data.city == '') {
                this.alert.register.type = 'alert-danger'
                this.alert.register.message = 'O CEP informado é inválido.'
            } else {
              this.alert.register.type = ''
              this.alert.register.message = ''

              if (response.data.address) {
                this.register.address = response.data.address
              }
              if (response.data.district) {
                this.register.district = response.data.district
              }
              this.register.city = response.data.city
              this.register.state = response.data.state
            }
          })
      }
    },
    searchCepEdit() {
      if (this.edit.zipcode.length == 9) {
        api
          .get("https://cep.m2n.com.br/" + this.edit.zipcode.replace(/\D/g, ""))
          .then(response => {
            if (response.data.city == '') {
                this.alert.edit.type = 'alert-danger'
                this.alert.edit.message = 'O CEP informado é inválido.'
            } else {
              this.alert.edit.type = ''
              this.alert.edit.message = ''

              if (response.data.address) {
                this.edit.address = response.data.address
              }
              if (response.data.district) {
                this.edit.district = response.data.district
              }
              this.edit.city = response.data.city
              this.edit.state = response.data.state
            }
          })
      }
    },
    getShipping() {
      this.shipping.loading = true
      api
        .get('store/shipping')
        .then(response => {
          if (response.data.status=='success') {
            this.shipping.services = response.data.services
          }

          this.shipping.loading = false
        })
    },
    setShipping(v) {
      if (v) {
        api
          .post('store/shipping',{
            method: v
          })
          .then(response => {
            if (response.data.status=='success') {
              localStorage.removeItem('cart')
              this.$parent.getCart()
            }
          })
      }
    }
  },
  mounted() {
    this.getCart()
    this.getAddress()
  },
  watch: {
    '$parent.$parent.cart.subtotal': function () {
      this.getShipping()
    },
    'shipping.method': function (v) {
      this.setShipping(v)
    }
  }
};
</script>

<template>
  <div>
    <div v-if="address.loading" class="text-center">
      <b-spinner small class="align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else-if="address.errored">
      Encontramos um erro ao carregar as informações, recarregue a página e tente novamente.
    </div>
    <div v-else-if="!address.status">
      <p>Você ainda não possui um endereço cadastrado.</p>
      <button @click="modal.register = true" class="btn btn-default btn-block font-weight-medium text-uppercase py-2">
        <i class="bx bx-home-alt font-size-24 align-middle pb-1"></i>
        Cadastrar
      </button>
    </div>
    <div v-else>
      <div v-if="cart.shipping.address.address" class="bg-soft-warning rounded p-3 mb-3 font-size-13">
        <strong>Endereço de Entrega</strong>
        <button  @click="modal.manage = true" class="btn btn-link p-0 ml-3 font-size-13 text-decoration-none">Trocar</button><br>
        {{ cart.shipping.address.address }},
        {{ cart.shipping.address.number }},
        {{ cart.shipping.address.city }}/{{ cart.shipping.address.state }},
        {{ cart.shipping.address.zipcode }}
      </div>
      <div v-else>
        <button @click="modal.manage = true" class="btn btn-default btn-block font-weight-medium text-uppercase py-2">
          <i class="bx bx-home-alt font-size-24 align-middle pb-1"></i>
          Selecionar endereço
        </button>
      </div>
      <div v-if="shipping.loading" class="text-center">
        <hr>
        <b-spinner small class="align-middle" variant="default" role="status"></b-spinner>
      </div>
      <div v-else-if="shipping.services && shipping.services.length > 0">
        <hr>
        <b-form>
          <b-form-group v-for="(shi,id) in shipping.services" :key="id" class="mb-2">
            <div class="bg-soft-info rounded p-3">
              <b-form-radio v-if="shi.custom === 'yes'" v-model="shipping.method" name="some-radios" :value="shi.code">{{ shi.name }} – {{ shi.value | currency }}</b-form-radio>
              <b-form-radio v-else-if="shi.code === 'ACOMBINAR'" v-model="shipping.method" name="some-radios" :value="shi.code">Receber a combinar em até {{ shi.time }}</b-form-radio>
              <b-form-radio v-else-if="shi.free === 'yes'" v-model="shipping.method" name="some-radios" :value="shi.code">Receber em {{ shi.time }} <span class="badge badge-warning font-size-13 text-uppercase text-dark p-1 px-2">frete grátis</span></b-form-radio>
              <b-form-radio v-else v-model="shipping.method" name="some-radios" :value="shi.code">Receber em {{ shi.time }} por {{ shi.value | currency }}</b-form-radio>
            </div>
          </b-form-group>
        </b-form>
      </div>
    </div>

    <b-modal v-model="modal.manage" title="Meus Endereços" centered hide-footer>
      <div v-for="(adr,id) in address.list" :key="id" class="mb-3">
        <div class="d-flex align-items-center">
          <div class="flex-fill bg-soft-warning rounded p-3 mr-2">
            {{ adr.address }},
            {{ adr.number }},
            <span v-if="adr.complement">{{ adr.complement }},</span>
            {{ adr.city }}/{{ adr.state }},
            {{ adr.zipcode }}
          </div>
          <div>
            <button v-on:click="showAddress(id)" class="btn btn-link btn-sm text-dark px-0"><i class="bx bx-edit align-middle font-size-20"></i></button>
          </div>
          <div>
            <button v-on:click="delAddress(id)" class="btn btn-link btn-sm text-dark px-0"><i class="bx bx-trash align-middle font-size-20"></i></button>
          </div>
        </div>
        <button v-if="adr.selected === 'yes'" class="btn btn-link text-decoration-none">
          <i class="bx bx-checkbox-checked font-size-24 align-middle"></i>
          Endereço principal
        </button>
        <button v-else @click="useAddress(id)" class="btn btn-link text-decoration-none">
          <i class="bx bx-checkbox font-size-24 align-middle"></i>
          Selecionar endereço
        </button>
        <hr>
      </div>
      <button v-on:click="modal.manage = false; modal.register = true;" class="btn btn-link btn-block text-default text-decoration-none border p-5">
        <i class="bx bx-plus-circle font-size-24"></i><br>
        Novo endereço
      </button>
    </b-modal>

    <b-modal v-model="modal.register" title="Endereço de Entrega" centered hide-footer>
      <b-form @submit.prevent="registerAddress">
        <div v-if="alert.register.message" :class="'alert ' + alert.register.type">{{ t(alert.register.message) }}</div>
        <b-form-group id="register.zipcode" label="CEP" label-for="register.zipcode">
          <b-form-input v-on:blur="searchCepRegister()" id="register.zipcode" v-model="register.zipcode" type="text" :class="{ 'is-invalid': $v.register.zipcode.$error }" autocomplete="off" v-mask="'#####-###'"></b-form-input>
          <div v-if="$v.register.zipcode.$error" class="invalid-feedback">
            <span v-if="!$v.register.zipcode.required">O CEP é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.address" label="Endereço" label-for="register.address">
          <b-form-input id="register.address" v-model="register.address" type="text" :class="{ 'is-invalid': $v.register.address.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.register.address.$error" class="invalid-feedback">
            <span v-if="!$v.register.address.required">O endereço é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.number" label="Número" label-for="register.number">
          <b-form-input id="register.number" v-model="register.number" type="text" :class="{ 'is-invalid': $v.register.number.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.register.number.$error" class="invalid-feedback">
            <span v-if="!$v.register.number.required">O número é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.complement" label="Complemento" label-for="register.complement">
          <b-form-input id="register.complement" v-model="register.complement" type="text" :class="{ 'is-invalid': $v.register.complement.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.register.complement.$error" class="invalid-feedback">
            <span v-if="!$v.register.complement.required">O complemento é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.district" label="Bairro" label-for="register.district">
          <b-form-input id="register.district" v-model="register.district" type="text" :class="{ 'is-invalid': $v.register.district.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.register.district.$error" class="invalid-feedback">
            <span v-if="!$v.register.district.required">O bairro é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.city" label="Cidade" label-for="register.city">
          <b-form-input id="register.city" class="bg-soft-info" v-model="register.city" type="text" :class="{ 'is-invalid': $v.register.city.$error }" autocomplete="off" readonly="readonly"></b-form-input>
          <div v-if="$v.register.city.$error" class="invalid-feedback">
            <span v-if="!$v.register.city.required">A cidade é obrigatória.</span>
          </div>
        </b-form-group>
        <b-form-group id="register.state" label="Estado" label-for="register.state">
          <b-form-input id="register.state" class="bg-soft-info" v-model="register.state" type="text" :class="{ 'is-invalid': $v.register.state.$error }" autocomplete="off" readonly="readonly" v-mask="'XX'"></b-form-input>
          <div v-if="$v.register.state.$error" class="invalid-feedback">
            <span v-if="!$v.register.state.required">O estado é obrigatório.</span>
          </div>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.register.loading" type="submit" variant="default">
          Cadastrar
          <b-spinner v-if="register.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>

    <b-modal v-model="modal.edit" title="Endereço de Entrega" centered hide-footer>
      <b-form @submit.prevent="editAddress">
        <div v-if="alert.edit.message" :class="'alert ' + alert.edit.type">{{ t(alert.edit.message) }}</div>
        <b-form-group id="edit.zipcode" label="CEP" label-for="edit.zipcode">
          <b-form-input v-on:blur="searchCepEdit()" id="edit.zipcode" v-model="edit.zipcode" type="text" :class="{ 'is-invalid': $v.edit.zipcode.$error }" autocomplete="off" v-mask="'#####-###'"></b-form-input>
          <div v-if="$v.edit.zipcode.$error" class="invalid-feedback">
            <span v-if="!$v.edit.zipcode.required">O CEP é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.address" label="Endereço" label-for="edit.address">
          <b-form-input id="edit.address" v-model="edit.address" type="text" :class="{ 'is-invalid': $v.edit.address.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.edit.address.$error" class="invalid-feedback">
            <span v-if="!$v.edit.address.required">O endereço é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.number" label="Número" label-for="edit.number">
          <b-form-input id="edit.number" v-model="edit.number" type="text" :class="{ 'is-invalid': $v.edit.number.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.edit.number.$error" class="invalid-feedback">
            <span v-if="!$v.edit.number.required">O número é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.complement" label="Complemento" label-for="edit.complement">
          <b-form-input id="edit.complement" v-model="edit.complement" type="text" :class="{ 'is-invalid': $v.edit.complement.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.edit.complement.$error" class="invalid-feedback">
            <span v-if="!$v.edit.complement.required">O complemento é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.district" label="Bairro" label-for="edit.district">
          <b-form-input id="edit.district" v-model="edit.district" type="text" :class="{ 'is-invalid': $v.edit.district.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.edit.district.$error" class="invalid-feedback">
            <span v-if="!$v.edit.district.required">O bairro é obrigatório.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.city" label="Cidade" label-for="edit.city">
          <b-form-input id="edit.city" class="bg-soft-info" v-model="edit.city" type="text" :class="{ 'is-invalid': $v.edit.city.$error }" autocomplete="off" readonly="readonly"></b-form-input>
          <div v-if="$v.edit.city.$error" class="invalid-feedback">
            <span v-if="!$v.edit.city.required">A cidade é obrigatória.</span>
          </div>
        </b-form-group>
        <b-form-group id="edit.state" label="Estado" label-for="edit.state">
          <b-form-input id="edit.state" class="bg-soft-info" v-model="edit.state" type="text" :class="{ 'is-invalid': $v.edit.state.$error }" autocomplete="off" readonly="readonly" v-mask="'XX'"></b-form-input>
          <div v-if="$v.edit.state.$error" class="invalid-feedback">
            <span v-if="!$v.edit.state.required">O estado é obrigatório.</span>
          </div>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.edit.loading" type="submit" variant="default">
          Editar
          <b-spinner v-if="edit.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>